/* Import Audiowide font */
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

body {
  font-family: 'Audiowide', sans-serif;
  text-align: center;
  color: white;
  overflow: hidden; /* Prevents scrolling from firefly animations */
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  position: relative;
}

/* Centered text content */
.content {
  position: relative;
  z-index: 6; /* Ensures text stays above fireflies */
  text-align: center;
}

/* Titles & Announcements */
.band-name {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  animation: text-focus-in 1.2s ease-out;
}

.album-announcement {
  font-size: 1.5rem;
  margin-bottom: 20px;
  animation: text-focus-in 1.9s ease-out;
}

/* Buttons */
.button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.link-btn {
  display: inline-block;
  padding: 12px 24px;
  width: 200px;
  font-size: 1.2rem;
  text-align: center;
  color: white;
  background-color: black;
  border: 2px solid cyan;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.link-btn:hover {
  background-color: cyan;
  color: black;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .link-btn {
    width: 80%; /* Make buttons wider on mobile */
  }
}
